import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  template: ''  // No template needed, since this component only handles redirection
})
export class RedirectComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    // Extract the 'lang' parameter and navigate
    this.route.paramMap.subscribe(params => {
      const lang = params.get('lang');
      this.router.navigate([`${lang}/service/021000337`]);
    });
  }
}
